import React from 'react'
import Header from './header'

import '../styles/layout.css'
import styles from './layout.module.css'
import {JoyceMLogo} from "./joyce-m-logo";

const Layout = ({children, onHideNav, onShowNav, showNav, siteData}) => (
  <>
    <Header siteData={siteData} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div style={{ height: '40px' }}>
          <JoyceMLogo fill="#1f1f1f" style={{ height: '100%' }}/>
        </div>
        <div className={styles.siteInfo}>
          &copy; {new Date().getFullYear()} JoyceM
        </div>
      </div>
    </footer>
  </>
)

export default Layout
