import { Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'

import styles from './header.module.css'
import {JoyceMLogo} from "./joyce-m-logo";
import {THEME_COLOR} from "./seo";

const FACEBOOK_LOGO = "https://img.icons8.com/metro/26/000000/facebook-new--v2.png"

const Header = ({ onHideNav, onShowNav, showNav, siteData = {} }) =>
  (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.branding}>
          <Link to='/'>
            <div style={{ height: '60px' }} aria-label={siteData.title}>
              <JoyceMLogo fill={THEME_COLOR} style={{ height: '100%' }}/>
            </div>
          </Link>
        </div>

        <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
          <Icon symbol='hamburger'/>
        </button>

        <nav className={cn(styles.nav, showNav && styles.showNav)}>
          <ul>
            <li>
              <Link to='/gallery/'>Gallery</Link>
            </li>
            <li>
              <Link to='/archive/'>Archive</Link>
            </li>
            {siteData.facebook_url && (
              <li>
                <a target="_blank" href={siteData.facebook_url}>
                  <img src={FACEBOOK_LOGO} alt="Facebook"/>
                </a>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  )

export default Header
