import React from 'react'

export const JoyceMLogo = ({ fill, style = {} }) => (
  <svg style={{ ...style, enableBackground: "new 0 0 618 212" }} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 618 212" xmlSpace="preserve">
    <path style={{ fill }} d="M188.7,111.87c-3.1,3.37-6.58,5.3-10.8,6.27c0.11,0.74,0.19,1.43,0.33,2.12c1.15,5.69,1.34,11.38-0.22,17.03
c-0.4,1.44-0.93,2.88-1.59,4.22c-3.2,6.51-9.32,9.56-16.47,8.29c-2.99-0.53-5.81-1.56-8.53-2.9c-0.54-0.26-1.07-0.53-1.56-0.77
c-2.97,1.42-5.79,1.19-8.54-0.34c-3.2-1.79-5.93-4.18-8.25-6.98c-9.44-11.38-14.98-24.39-16.01-39.19
c-0.17-2.44,0.07-4.96,0.52-7.37c1.59-8.5,7.8-13.69,16.46-13.97c4.66-0.15,9.12,0.87,13.37,2.69
c11.13,4.78,19.29,12.81,25.11,23.31c0.9,1.63,1.68,3.33,2.62,5.2c0.58-0.14,1.22-0.24,1.81-0.45c2.65-0.97,4.65-2.74,6.21-5.05
c0.37-0.55,0.69-1.13,1.02-1.71c1.54-2.74,2.01-5.43,0.84-8.6c-2.05-5.57-4.22-11.04-7.68-15.9c-0.14-0.2-0.27-0.4-0.38-0.62
c-0.04-0.09-0.01-0.23-0.01-0.47c2.26-0.47,4.5-0.22,6.72,0.31c4.03,0.97,7.03,3.39,9.12,6.91c0.67,1.13,1.4,1.74,2.72,2
c3.47,0.68,6.08,2.7,7.46,5.96c0.81,1.92,1.4,4,1.71,6.06c1.17,7.87,2.3,15.76,3.23,23.66c0.57,4.85,1.68,9.51,3.78,13.89
c1.01,2.1,2.19,4.2,3.67,5.98c3.43,4.1,7.33,3.66,10.05-0.96c1.5-2.54,2.19-5.35,2.54-8.24c0.84-7.01-0.05-13.81-2.34-20.49
c-1.92-5.58-3.72-11.21-5.37-16.88c-0.62-2.12-0.68-4.39-0.96-6.6c-0.06-0.47-0.01-0.97,0.03-1.45c0.33-3.99,2.4-5.87,6.41-5.67
c1.25,0.06,2.5,0.36,3.72,0.67c0.85,0.21,1.57,0.22,2.43-0.09c4.4-1.55,8.79-1.35,13.04,0.52c1.06,0.47,2.01,1.32,2.83,2.16
c0.76,0.78,0.66,1.43-0.33,1.89c-3.16,1.46-3.98,4.35-4.58,7.34c-0.86,4.26-0.37,8.54,0.17,12.79c1.23,9.79,3.8,19.28,6.5,28.73
c3.11,10.9,6.34,21.76,9.35,32.69c1.02,3.71,1.66,7.54,2.18,11.35c0.28,2.06,0.15,4.22-0.13,6.29c-0.39,2.85-1.59,3.89-4.54,4.46
c-0.14,1.02-0.26,2.09-0.43,3.15c-0.74,4.53-3.08,8.06-6.82,10.68c-3.33,2.34-7.1,3.52-11.09,4.09c-8.77,1.24-17.04-0.68-24.99-4.2
c-3.02-1.34-5.92-2.98-8.73-4.73c-1.56-0.97-2.88-2.37-4.19-3.68c-0.86-0.86-1.36-1.93-1.33-3.26c0.01-0.49-0.46-1.03-0.8-1.48
c-1.96-2.52-4.08-4.93-5.88-7.56c-2.23-3.27-3.65-6.91-4.16-10.89c-0.9-7.09,3.16-12.61,10.19-13.84
c5.25-0.92,10.22,0.04,14.94,2.31c6.63,3.19,11.94,7.93,15.69,14.28c1,1.69,1.75,3.59,2.21,5.5c0.87,3.57-0.92,5.55-4.62,5.45
c-0.48-0.01-0.95-0.08-1.61-0.14c1.64-3.4,0.01-6.09-1.59-8.82c-0.18,0.1-0.33,0.13-0.38,0.22c-1.22,2.19-3.31,2.32-5.52,1.38
c-2.81-1.19-5.27-2.97-7.33-5.19c-2.56-2.75-5.62-4.49-9.26-5.3c-2.82-0.62-5.23,0.98-5.65,3.83c-0.27,1.83,0.02,3.6,0.6,5.35
c1.52,4.62,4.22,8.53,7.48,12.05c5.4,5.83,11.8,10.09,19.63,11.98c2.9,0.7,5.8,1.03,8.76,0.28c3.27-0.83,5.76-2.68,7.52-5.53
c1.95-3.16,2.71-6.68,3.09-10.31c0.71-6.79-0.07-13.5-1.17-20.17c-1.31-7.92-3.28-15.7-5.57-23.39c-0.07-0.23-0.15-0.45-0.31-0.91
c-0.25,0.51-0.42,0.83-0.57,1.18c-0.45,1.05-0.83,2.14-1.35,3.16c-2.24,4.35-5.87,6.78-10.69,7.4c-7.58,0.97-14.01-1.61-19.59-6.56
c-4.81-4.27-7.91-9.68-10.01-15.69c-1.96-5.6-3.44-11.3-3.94-17.23C188.86,113.02,188.8,112.68,188.7,111.87z M127.75,95.28
c0.15,0.37,0.26,0.75,0.46,1.09c1.36,2.34,2.76,4.66,4.1,7.01c0.43,0.77,0.88,1.6,1.01,2.45c1.2,7.79,3.87,15.04,7.82,21.83
c2.44,4.19,5.33,8.04,9.12,11.13c2.36,1.93,4.96,3.42,7.96,4.1c4.46,1.01,7.87-0.66,9.73-4.84c0.44-0.98,0.76-2.03,1.01-3.08
c0.95-4.06,0.74-8.14,0.17-12.23c-0.16-1.17-0.43-2.32-0.63-3.38C150.28,118.88,137.41,109.86,127.75,95.28z M166.44,111.4
c-0.67-1.66-1.19-3.06-1.78-4.42c-2.31-5.29-5.21-10.2-9.39-14.25c-2.46-2.38-5.26-4.19-8.57-5.2c-2.09-0.64-4.16-0.66-6.24-0.12
c-3.31,0.86-4.76,3.26-3.87,6.57c0.32,1.2,0.84,2.4,1.48,3.47c3.27,5.38,7.98,9.12,13.68,11.65
C156.3,111.13,161.08,111.8,166.44,111.4z"/>
    <path style={{ fill }} d="M85.89,9.48c0.74,0.86,0.58,1.4-0.34,2.11c-0.14,0.11-0.29,0.24-0.46,0.29c-2.21,0.73-3.06,2.62-3.78,4.55
c-1.5,4.02-1.37,8.19-0.89,12.34c1.21,10.37,4.15,20.32,7.99,29.98c4.07,10.25,8.42,20.4,12.61,30.61
c3.06,7.48,6.06,14.99,7.94,22.87c0.66,2.75,1.07,5.56,1.48,8.36c0.17,1.18,0.14,2.42,0,3.61c-0.42,3.79-1.48,4.94-5.14,5.74
c-0.2,1.85-0.27,3.61-0.61,5.32c-0.89,4.46-3.31,7.97-7,10.61c-3.92,2.81-8.39,4.2-13.12,4.8c-10.56,1.34-20.68-0.53-30.44-4.51
c-4.01-1.63-7.89-3.62-11.68-5.72c-1.97-1.09-3.69-2.7-5.39-4.21c-1.17-1.04-2.05-2.33-2.08-4.07c-0.01-0.48-0.54-1.02-0.96-1.41
c-3.42-3.16-6.59-6.55-9.25-10.39c-2.66-3.86-4.86-7.94-5.54-12.65c-0.26-1.77-0.31-3.64-0.04-5.39c0.7-4.49,3.41-7.5,7.58-9.16
c3.37-1.34,6.9-1.63,10.49-1.33c5.95,0.5,11.39,2.5,16.51,5.48c6.01,3.5,11.25,7.93,15.37,13.55c1.41,1.93,2.51,4.11,3.59,6.25
c0.47,0.94,0.67,2.07,0.78,3.14c0.25,2.38-0.97,3.94-3.3,4.42c-1.44,0.3-2.87,0.23-4.41-0.18c1.23-1.99,0.89-3.96-0.06-5.85
c-0.75-1.5-1.66-2.91-2.49-4.35c-0.29,0.14-0.42,0.16-0.47,0.24c-1.56,2.37-2.77,2.89-5.48,2.12c-1.26-0.36-2.51-0.87-3.66-1.51
c-2.8-1.55-5.43-3.35-7.76-5.58c-3.36-3.24-7.49-5.02-12.02-5.86c-2.92-0.54-5.58,1.56-5.72,4.61c-0.07,1.41,0.18,2.89,0.56,4.26
c0.89,3.18,2.62,5.95,4.63,8.54c6.76,8.69,15.45,14.84,25.56,18.98c3.44,1.41,7.09,2.33,10.66,3.41c2.74,0.82,5.52,0.56,8.22-0.18
c4.16-1.14,6.85-3.89,8.27-7.92c1.43-4.07,1.57-8.27,1.24-12.51c-0.75-9.45-3.28-18.5-6.2-27.46c-4.06-12.46-9.17-24.5-14.41-36.5
c-3.55-8.13-6.67-16.42-8.74-25.08c-1.07-4.47-1.84-8.99-1.63-13.61c0.08-1.79,0.35-3.57,0.55-5.48c-0.55-0.04-1.08-0.11-1.61-0.12
c-6.58-0.14-13.15-0.35-19.73-0.36c-1.95,0-3.99,0.31-5.85,0.91c-3.81,1.23-5.31,4.25-4.35,8.14c0.58,2.33,1.76,4.37,3.32,6.16
c5.05,5.82,11.27,9.14,19.15,8.9c0.29-0.01,0.58,0.04,0.85,0.07c0.91,4.1-0.94,6.98-4.96,7.45c-1.65,0.19-3.43,0.16-5.02-0.26
c-5.89-1.55-10.93-4.45-14.23-9.8c-0.51-0.82-1.02-1.66-1.32-2.57c-0.38-1.19-1.11-1.64-2.29-1.92c-4.35-1.03-8.28-2.97-11.62-5.98
c-2.69-2.43-4.79-5.26-5.46-8.94c-0.61-3.29,0.36-6.08,2.73-8.4c1.86-1.82,4.16-2.88,6.62-3.56c2.74-0.77,5.55-1.2,8.4-1.13
c12.67,0.31,25.33,0.65,38,0.96c4.88,0.12,9.75,0.58,14.65,0.24"/>
    <path style={{ fill }} d="M437.95,103.59c1.23-0.9,2.24-1.78,3.37-2.45c3.35-2,6.86-2.36,10.3-0.32c3.47,2.06,5.31,5.22,5.31,9.29
c0,3.11-1.44,5.5-4.1,7.46c-0.16-0.66-0.29-1.1-0.38-1.55c-0.98-4.78-4.6-7.13-9.37-6.09c-6,1.31-10.3,7.07-10.11,13.5
c0.25,8.26,6.62,16.54,14.54,18.91c7.46,2.23,15.38-0.34,20.22-6.59c3.48-4.5,5.02-9.71,5.36-15.3c0.42-6.88-0.6-13.63-2.4-20.22
c-2.51-9.19-5.21-18.32-7.97-27.44c-2.99-9.88-5.55-19.86-7.2-30.05c-0.69-4.23-1.07-8.5-0.92-12.8c0.08-2.3,0.38-4.57,1.19-6.74
c1.88-5.06,7.31-6.7,11.85-3.73c3.79,2.48,6.78,5.78,9.76,9.1c0.27,0.31,0.47,0.7,0.63,1.08c4.82,11.24,10.9,21.77,17.66,31.94
c7.45,11.2,14.79,22.47,22.16,33.73c1.89,2.88,3.63,5.85,4.6,9.19c0.33,1.15,0.56,2.35,0.65,3.54c0.22,3.03-1.13,5.22-3.79,6.63
c-0.37,0.2-0.75,0.39-1.25,0.65c0.1,3.15-0.28,6.22-2.67,8.65c-1.73,1.76-4.42,3.04-7.48,3.49c-0.08-0.18-0.24-0.38-0.26-0.59
c-0.63-7.68-3.12-14.84-6.07-21.87c-4.36-10.37-9.83-20.15-15.63-29.77c-1.74-2.89-3.55-5.75-5.34-8.61
c-0.3-0.49-0.66-0.94-1.24-1.29c0.73,2.78,1.54,5.55,2.18,8.35c2.1,9.23,4.32,18.43,6.15,27.71c1.52,7.71,1.44,15.48-0.82,23.11
c-1.71,5.76-4.53,10.91-8.4,15.48c-5.45,6.43-12.5,9.81-20.83,10.79c-19.9,2.33-34.94-11.49-39.08-26.69
c-1.32-4.83-1.06-9.57,1.11-14.12c3.21-6.74,9.5-9.36,16.58-6.99C436.82,103.17,437.38,103.38,437.95,103.59z"/>
    <path style={{ fill }} d="M292.7,107.75c1.22-0.06,2.31-0.04,3.38-0.19c3.3-0.45,4.98-2.62,4.63-5.94c-0.06-0.6-0.17-1.21-0.37-1.77
c-1.75-4.89-4.47-9.08-8.83-12.06c-2.24-1.53-4.7-2.4-7.46-2.4c-3.28,0-5.82,1.38-7.65,4.06c-1.61,2.36-2.27,5.04-2.46,7.85
c-0.43,6.26,0.91,12.24,3.02,18.07c2.02,5.6,4.81,10.79,8.6,15.41c2.59,3.15,5.52,5.93,9.18,7.8c6.46,3.29,12.85,3.46,18.98-0.83
c3.26-2.28,5.48-5.45,6.84-9.14c0.81-2.19,1.24-4.52,1.83-6.79c0.21-0.8,0.39-1.61,0.64-2.62c-2.03-3.58-3.85-7.52-5.1-11.7
c-1.4-4.67-2.09-9.4-1.3-14.29c1.39-8.58,7.74-14.98,16.32-16.45c5.73-0.98,11.2-0.01,16.4,2.36c9.5,4.34,16.88,11.11,22.21,20.05
c1.07,1.79,1.65,3.86,2.42,5.82c0.15,0.38,0.19,0.82,0.22,1.24c0.2,2.85-1.21,4.31-4.07,4.21c-0.6-0.02-1.19-0.11-1.9-0.17
c-0.18,0.49-0.37,0.99-0.53,1.49c-1.85,5.74-6.49,9.27-12.52,9.57c-4.83,0.24-9.3-1.1-13.63-3.05c-0.64-0.29-1.29-0.59-2.18-1
c0.18,0.62,0.26,1.01,0.41,1.36c2.52,5.82,5.84,11.09,10.44,15.51c2.59,2.49,5.49,4.51,8.89,5.77c6.96,2.6,14.16,0.79,19.05-4.82
c2.94-3.36,4.58-7.34,5.46-11.66c0.12-0.59,0.21-1.19,0.31-1.78c0.31-1.95,0.79-2.58,2.76-2.65c1.41-0.05,2.85,0.31,4.24,0.63
c0.74,0.17,1.11,0.8,1.12,1.66c0.06,6.26-0.9,12.28-4.2,17.72c-3.71,6.12-9.26,9.54-16.28,10.54c-8.28,1.18-15.92-0.95-23.14-4.84
c-6.28-3.38-11.67-7.9-16.44-13.19c-0.64-0.71-1.27-1.43-2.09-2.35c-0.21,0.76-0.39,1.3-0.51,1.85c-0.63,2.72-1.57,5.32-3,7.73
c-3.73,6.29-9.3,9.82-16.48,10.82c-7.96,1.11-15.33-0.9-22.31-4.56c-9.33-4.89-16.72-11.99-22.74-20.56
c-4.51-6.43-7.94-13.37-9.53-21.13c-0.82-4.01-0.97-8.03,0.1-12.02c2.19-8.09,7.54-12.86,15.66-14.5
c6.14-1.24,12.02-0.04,17.58,2.63c9.11,4.38,16.24,10.97,21.45,19.63c0.8,1.33,1.42,2.8,1.98,4.26c0.36,0.94,0.58,1.98,0.64,2.99
c0.18,2.82-1.41,4.5-4.2,3.96c-2.33-0.45-4.3-0.22-6.3,1c-0.74,0.45-1.7,0.61-2.58,0.77C296.58,112.61,293.89,110.96,292.7,107.75z
M350.84,115.51c0.6,0,1.21,0.04,1.81-0.01c4.76-0.4,8.15-2.95,9.13-7.17c0.39-1.67,0.5-3.48,0.34-5.19
c-0.42-4.44-2.24-8.36-5.09-11.77c-2.34-2.8-5.18-4.85-8.79-5.69c-4.74-1.1-9.02,0.9-11.09,5.32c-0.58,1.24-1.01,2.59-1.28,3.93
c-0.83,4.15-0.5,8.3,0.41,12.39c0.92,4.15,0.06,3.1,3.91,5.21C343.49,114.34,346.99,115.59,350.84,115.51z"/>
    <path style={{ fill }} d="M497.44,35.72c1.27-0.95,2.22-1.8,3.29-2.44c2.81-1.69,5.57-1.43,7.99,0.75c0.89,0.8,1.61,1.79,2.39,2.7
c0.34,0.4,0.62,0.84,1.02,1.4c0.3-0.48,0.51-0.83,0.72-1.18c2.19-3.63,5.71-5,9.77-3.75c3.32,1.03,5.75,3.21,7.64,6.05
c1.97,2.95,3.1,6.25,4.02,9.64c5.27,19.31,11.26,38.4,17.72,57.35c1.83,5.37,3.97,10.62,6.68,15.61c2.09,3.83,4.51,7.42,7.62,10.51
c3.4,3.38,7.33,5.9,12.03,7.04c10.83,2.63,21.51-4.28,23.62-15.25c0.9-4.69,0.46-9.25-1.44-13.64c-2.54-5.86-7.83-9.13-14.08-8.74
c-2.04,0.13-3.89,0.79-5.57,1.93c-2.91,1.98-3.97,5.01-2.94,8.39c0.26,0.86,0.63,1.68,1.03,2.72c-2.24,0.09-4.1-0.56-5.79-1.77
c-3.39-2.44-4.84-7.1-3.62-11.52c1.13-4.1,4.59-6.99,8.91-7.32c1.61-0.13,3.25-0.02,4.96-0.02c0.58-0.75,1.17-1.58,1.83-2.36
c4.24-5.03,10.19-6.36,16.14-3.56c4.75,2.23,8.11,5.89,10,10.73c7.81,19.93-2.11,44.68-26.35,50.33
c-6.81,1.59-13.39,0.49-19.82-2.11c-9.73-3.94-16.95-10.67-22.23-19.62c-3.23-5.47-5.66-11.32-7.95-17.23
c-4.52-11.69-8.91-23.44-13.49-35.11c-3.53-8.98-7.34-17.85-12.26-26.18C506.27,43.96,503.02,39.03,497.44,35.72z"/>
    <path style={{ fill: 'transparent' }} d="M127.75,95.28c9.66,14.58,22.52,23.59,40.74,24.08c0.2,1.06,0.47,2.22,0.63,3.38
c0.58,4.08,0.79,8.17-0.17,12.23c-0.25,1.05-0.57,2.1-1.01,3.08c-1.86,4.18-5.27,5.85-9.73,4.84c-3.01-0.68-5.6-2.17-7.96-4.1
c-3.79-3.1-6.69-6.94-9.12-11.13c-3.94-6.79-6.62-14.04-7.82-21.83c-0.13-0.85-0.58-1.68-1.01-2.45c-1.33-2.36-2.74-4.67-4.1-7.01
C128.02,96.04,127.91,95.65,127.75,95.28z"/>
    <path style={{ fill: 'transparent' }} d="M166.44,111.4c-5.36,0.39-10.14-0.27-14.68-2.29c-5.69-2.53-10.41-6.27-13.68-11.65
c-0.65-1.07-1.16-2.26-1.48-3.47c-0.89-3.31,0.56-5.71,3.87-6.57c2.08-0.54,4.15-0.52,6.24,0.12c3.31,1.01,6.11,2.82,8.57,5.2
c4.18,4.05,7.08,8.96,9.39,14.25C165.25,108.34,165.77,109.74,166.44,111.4z"/>
    <path style={{ fill: 'transparent' }} d="M350.84,115.51c-3.85,0.08-7.36-1.17-10.66-2.98c-3.85-2.11-2.99-1.06-3.91-5.21
c-0.91-4.08-1.24-8.24-0.41-12.39c0.27-1.35,0.7-2.69,1.28-3.93c2.07-4.41,6.35-6.42,11.09-5.32c3.62,0.84,6.46,2.89,8.79,5.69
c2.85,3.42,4.67,7.33,5.09,11.77c0.16,1.71,0.05,3.52-0.34,5.19c-0.99,4.22-4.37,6.77-9.13,7.17
C352.05,115.55,351.45,115.51,350.84,115.51z"/>
</svg>

)
